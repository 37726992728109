.footer-title{border-bottom: solid 1px #ffffff; padding-bottom: 10px; margin-bottom: 20px !important; font-size: 20px !important; font-weight: 600 !important;   font-family: "Roboto Condensed", serif;}
.footer-list{margin: 0; padding: 0;}
.footer-list li{margin: 0 0 10px 0; padding: 0;}
.footer-list li a{position: relative; padding-left: 40px;}
.footer-list li a svg{position: absolute; left: 0; top: 0;}

.social-icons a{display: inline-block; width: 35px; height: 35px; background: #fff; border-radius: 100%; text-align: center; line-height: 45px; margin: 0 15px 0 0;}
.timings li{align-items: start;}
.timings p{max-width: 70%;}
.copyrights .copyinner{display: flex; justify-content: space-between;}
.copyrights .copyinner a{color: #ffffff;}
.copyrights .copyinner a img{height: 40px;     display: inline-block;
    vertical-align: middle;}

    .footer-timings ul {margin: 0; padding: 0;}
.footer-timings ul li{position: relative; list-style: none; padding-left: 180px; margin-bottom: 15px;}
.footer-timings ul li p{margin: 0; padding: 0;}
.footer-timings ul li p:first-child{position: absolute; left: 0; top: 0;}
.footer{position: relative;}
.audio-god-song{position: absolute; right: 10px; bottom: 0;}

.audio-god-song audio {
    width: 150px; 
    border-radius: 10px; 
    background: #f5f5f5;
    padding: 5px !important;
    margin: 0 !important;
  }
  

  audio::-webkit-media-controls-current-time-display,
  audio::-webkit-media-controls-time-remaining-display,
  audio::-webkit-media-controls-timeline {
    display: none;
  }