@media (min-width: 992px) {
.min-height{min-height: 500px;}
}
@media (max-width: 1700px) {
    .nav li{margin: 0 8px;}
    .nav li a, .nav li a span{font-size: 18px; padding: 8px 8px;}
    .logo{max-width: 200px;}
    .banner{padding:  0;}
    .banner h1 {font-size: 28px; line-height: 28px; }
    .banner a{margin-top: 5px !important;}
    .call-for-action-block{padding-left: 40px;}
    .call-for-action-block h5{font-size: 16px !important;}
    .call-for-action-section span{width: 30px; height: 30px; line-height: 30px;}
.call-for-action-section svg { height: 25px; width: 25px; top: 4px;}
.upcoming-events .section-title{font-size: 40px; line-height: 40px; margin-bottom: 20px !important;}
.theme-radius {border-radius: 5px; border-top-right-radius: 30px !important;}
.event-img{height: 150px !important;}
.event-lists .event-card {min-height: 315px;}
.static-event-card{height: 317px;}
.css-5wchs2-MuiDateCalendar-root{max-height: 280px !important;}
.banner p{font-size: 14px !important; line-height: 18px !important;}
.callforactiondiv .MuiContainer-root{padding-left: 0; padding-right: 0;}
.call-for-action-section span{right: -10px;}
.call-for-action-block img{left: -10px;}

.footer{font-size: 13px !important;}
.breadcrumb li, .breadcrumb li a{font-size: 12px;}
.annual-sponsorship-lists .MuiGrid2-grid-md-4{ height: 430px;  }

}

@media (max-width: 1500px) {

    .audio-god-song {
        position: relative;
        right: 0;
        bottom: 0;
        background: var(--clrPrimary);
        padding: 10px 0 30px 0;
    }
    


.audio-god-song audio{
    margin: 0 auto !important;
    display: block;
    width: 300px;
}

audio::-webkit-media-controls-current-time-display{ display: block;
}
audio::-webkit-media-controls-time-remaining-display{display: block;}
audio::-webkit-media-controls-timeline {
  display: block;
}
}

@media (max-width: 1400px) {

    .nav li{margin: 0 5px;}
    .nav li a, .nav li a span {
        font-size: 17px;
        padding: 8px 8px;
    }
    .face-book-block button, .face-book-block a {
        font-size: 16px !important;
        color: #000 !important;
    }

}

@media (max-width: 1300px) {

    .nav li{margin: 0 3px;}
    .nav li a, .nav li a span {
        font-size: 16px;
        padding: 8px 5px;
    }

}


@media (max-width: 992px) {
    .annual-sponsorship-lists .MuiGrid2-grid-md-4{ height: auto;  }
.logo{position: relative; border: none; padding: 0; margin: 0;     width: 150px; display: block; }
header{padding: 0 0 15px 0; border-top: solid 10px var(--clrPrimary);}
.header-container{position: absolute; top: 0; right: 0;}
.theme-radius {border-top-right-radius: 20px !important;}
.banner{background: none !important; padding: 0 !important; text-align: center;}
.banner img{margin-bottom: 30px;}
.banner::before{display: none;}
h1{font-size: 2rem !important; line-height: 2rem !important; margin-bottom: 0.35em;}
h2{font-size: 1.75rem !important; line-height: 1.75rem !important; margin-bottom: 0.35em;}
.banner h1{color: var(--clrPrimary);}
.lead {font-size: 18px !important; line-height: 30px !important;}
.banner .lead{color: #000;}
h3{font-size: 1.5rem !important; line-height: 1.5rem !important; margin-bottom: 0.35em;}
.icon-large{width: 35px !important;}

.event-calender{margin: 0 !important;}
.slick-prev {left: -10px;}
.slick-next {right: -10px; top: 44%;}
.form{width: 100%;}
.image-gallery{padding-top: 3rem;}
.image-gallery ul{display:  block !important;}
.image-gallery ul li{margin-bottom: 30px !important; height: auto !important;}
.image-gallery ul li img{height: auto !important;}
.prizes-radio .radiogroup{display: block;}
.prizes-radio  .MuiSheet-root{margin-bottom: 30px;}
.payment-radio-box{padding: 50px 0 !important;}
.inline-radio {flex-direction: column !important;}
.inline-radio li {max-width: 100% !important; width: 100% !important; margin: 10px 0 !important;}

.banner-pic, .banner-pic img {height: auto !important; margin: 0; }  
.css-gcpdnc{padding: 0 !important;}
.text-inner{margin-bottom: 10px;}
.full-block{width: 100%;}
.balvihar_deepam{ padding-top: 30px; width: 100%; display: block;}
.balvihar_deepam img{width: 100% !important; height: auto !important;}
.balvihar_deepam .card{display: block; width: 100%;}
.balvihar_deepam .block{display: block; width: 100%;}
.copyrights .copyinner{display: block; text-align: center !important;}
.footer-inner{border-radius: 0 !important;}
.page-tile-section{width: 100%; text-align: center; margin-top: -100px;}

.nav{margin-left: 0 !important;}
.nav li:hover .sub-menu{display: none !important;}
.nav li a, .nav li a span{padding: 0 !important; font-size: 16px !important;}
.nav li{border-bottom: solid 1px #ccc !important; display: block;}
.nav li .sub-menu.active{display: block !important;}
.sub-menu{position: relative !important; width: 100% !important; padding: 5px 10px !important;}
.sub-menu::before{display: none !important;}
.footer-timings ul li{padding: 0;}
.footer-timings ul li p:first-child{position: relative; border-bottom: solid 1px #ccc; padding-bottom: 10px; margin-bottom: 10px;}



.callforaction{position: fixed; bottom: 0; right: 0;  z-index: 999 !important; background: #fff; width: 100%;  padding: 15px 0 5px 0; }
.callforaction .block{
    padding: 0 !important;
    display: inline-block !important; width: 50% !important;
      
        border: none !important;
        margin-bottom: 20px !important;
        background: none !important;
        margin: 0  !important;
}
.call-for-action-section span{display: none;}
.call-for-action-block h5{font-size: 12px !important; line-height: 16px !important; font-weight: bold; color: #000000 !important;}
.callforaction .MuiGrid2-container{display: block !important; text-align: center;}
.banner .icon-50, .inner-text .icon-50{    width: 40px !important;}
.call-for-action-block img {

    left: 50%;
    top: -40px;
    background: #980406;
    border-radius: 100%;
    padding: 8px;
    width: 40px !important;
    border: solid 1px #FFF;

    margin: 0  0 0 -20px !important;
}
.call-for-action-block {
    padding-left: 0;
    margin: 0 auto;
}
.inner-banner{margin-top: -35px;}
.inner-banner .section-title{margin-bottom: 0 !important;}

.custom-table{border: none !important;}
.custom-table thead{display: none !important;}
.custom-table tbody{display: block; width: 100%;}


.custom-table, .custom-table tr, .custom-table tr th, .custom-table tr td{display: block; width: 100%;}
.custom-table tr{margin-bottom: 30px; border: solid 1px #ccc;}
.custom-table tr td{position: relative; padding-left: 130px; text-align: left !important;}
.custom-table tr td::before{content: attr(data-label); position: absolute; left: 10px; top: 15px; font-size: 11px; font-weight: 700; color: var(--clrPrimary); font-weight: 700;}
.mob-none{display: none !important;}

.inner-text .section-title{text-align: center;}
.footer{margin-bottom: 60px;}

.mob-center{text-align: center;}
.mob-center img{margin: 0 auto;}
.staff-top{padding: 0; min-height: 1px; padding-bottom: 15px; margin-bottom: 15px;}
.staff-pic{position: relative; margin: 0 auto 15px auto !important;}
.staff-content, .staff-top h6{text-align: center;}

.static-event-card a{left: 26%;}
.face-book-block svg{width: 30px; height: 30px;}
.face-book-block button, .face-book-block a{font-size: 16px !important;}

.sub-menu li .sub-sub-menu.active{display: block !important; position: relative !important; width: 100% !important; left: 0 !important; top: 0 !important;}
.nav li li a svg.active{    transform: rotate(90deg);}
.nav li .sub-sub-menu a, .nav li .sub-sub-menu a span{font-size: 12px !important;}
.breadcrumb li, .breadcrumb li a{line-height: 6px;}
.static-event-card::before{border-top-right-radius: 30px;}
.balvihar_deepam .card{height: auto;}
.image-gallery .section-title{margin-bottom: 0 !important;}
.specify-page-buttons{display: block !important;}
.specify-page-buttons button{ width: 100%; margin-bottom: 15px;}
.project-cart-list button{margin-top: 10px;}
.mobile-card{position: relative;}
.mobile-card .checkout-footer{padding: 0 !important; margin: 0 !important; background: none !important; position: absolute; right: 0; top: 0;}
.mobile-card .border-title{font-size: 26px !important;}

.project-cart-list h5{font-size: 18px !important;}
.sticky{top: 0 !important; background: #fff; z-index: 999;}
.scan{text-align: center;}
.call-to-action .scan p{width: 100%;}

.addon-list{background: none; padding: 0;}
.addon-list-item { border-bottom: solid 1px #ccc; padding-bottom: 10px; margin-bottom: 10px;}
.addon-list-item:last-child{border: none;}
.addon-list-item > span{min-width: 80px; display: block; text-align: right !important;}
.border-title{    padding-bottom: 10px;
    margin-bottom: 10px !important;
    font-size: 26px !important;}
    .custom-card h4{ font-size: 26px !important;}
    .items-list span{font-size: 14px;}
    .items-list span:last-child{font-size: 20px; text-align: right; min-width: 70px;}

    .body-active .puja-request-form .items-list,   .body-active .selected-block{display: none; opacity: 0;}
    .body-active .puja-request-form   .border-title,     .body-active .social-hall-booking  .border-title{border: none !important; padding-bottom: 0 !important; margin-bottom: 0 !important;}
.check-box-popup{max-width: 96%; text-align: center;}
.check-box-popup li{text-align: left;}


    
}