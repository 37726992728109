@import url('https://fonts.googleapis.com/css2?family=El+Messiri:wght@400..700&family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');
@import url('../themeSettings/theme.css'); 

:root {
  --clrDark: #222222; 
  --clrLight: #fcf8f3;
  --clrWhite: #ffffff; 
  --clrBlack: #000000;
  --clrPrimary: #b40b0d;
  --clrSecondary: #4C0F0F;
  --clrTertiary: #770707;        
}

.roboto-condensed{
  font-family: "Roboto Condensed", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.el-messiri {
  font-family: "El Messiri", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.plus-jakarta-sans {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

a{text-decoration: none !important;}
a:hover{text-decoration: underline !important; }

body{
  background: var(--bgTheme) !important; 
  width: 100%; overflow-x: hidden;
}
.mt-30{margin-top: 30px !important;}

h1, .h1 { font-size: 2.5rem; margin: 0 0 15px 0; padding: 0; }
h2, .h2 { font-size: 2rem;  margin: 0 0 15px 0; padding: 0;}
h3, .h3 { font-size: 1.75rem; margin: 0 0 15px 0; padding: 0; }
h4, .h4 { font-size: 1.5rem; margin: 0 0 15px 0; padding: 0; }
h5, .h5 { font-size: 1.25rem; margin: 0 0 15px 0; padding: 0; }
h6, .h6 { font-size: 1rem; margin: 0 0 15px 0; padding: 0; }


.mb-0 {margin-bottom: 0;}
.pb-0{padding-bottom: 0;}

.m-0 {margin: 0;}
.p-0{padding: 0 !important;}
.mt-15{margin-top: 15px !important;}
.py-30{padding-top: 30px; padding-bottom: 30px;}
.mb-50{margin-bottom: 50px;}

.text-center{text-align: center;}
.custom-list,  .custom-list-check {margin: 0 0 0 20px; padding: 0; list-style: none;}
.custom-list li,  .custom-list-check li{margin-bottom: 15px; padding-left: 25px; position: relative;}
.custom-list li::before{content: ""; position: absolute; left: 0; top: 5px;
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-left: 10px solid var(--clrPrimary);
  border-bottom: 6px solid transparent;}

  .custom-list-check li::before{
    content: "";
    position: absolute;
    left: 0;
    top: 3px;
    transform: rotate(45deg);
    height: 18px;
    width: 9px;
    border-bottom: 3px solid var(--clrPrimary);
    border-right: 3px solid var(--clrPrimary);
  }

.object-center-top{object-position: center top !important; background-position: center top !important;  }





.p-15{padding: 15px;}
.mb-15{margin-bottom: 15px;}
.mb-30{margin-bottom: 30px;}
.card{background: #fff; border: solid 1px #f1f1f1; border-radius: 4px;}

.font-700{font-weight: 700 !important;}


section{padding: 3rem 0;}
.w-100 {
  width: 100%;
}
.h-100 {
  height: 100%;
}
.theme-radius{ border-radius:10px; border-top-right-radius: 50px !important;}
.text-white{color: var(--clrWhite) !important;}
.lead{font-size: 18px !important; line-height: 30px !important;}
.text-primary{color: var(--clrPrimary) !important;}

.bg-primary{background-color: var(--clrPrimary) !important;}
.bg-secondary{background-color: var(--clrSecondary) !important;}
.bg-tertiary{background-color: var(--clrTertiary) !important;}

.icon-large{width: 70px !important;}
.icon-50{width: 50px !important; height: auto !important;}




.banner .icon-50, .inner-text .icon-50{width: 30px !important;}

.font-13{font-size: 13px !important;}
.font-14{font-size: 14px !important;}
.font-15{font-size: 15px !important;}


.callforaction{position: relative;}
.callforaction .block{border: solid 1px #fff;}
.call-for-action-section{ position: relative;}
.call-for-action-section span{display: block; width: 30px; height: 30px; background: #fff; border-radius: 100%; text-align: center; line-height: 30px; position: absolute; right: 0; top: 0;}
.call-for-action-section svg{color:var(--clrPrimary); height: 20px; width: 20px;     position: relative;
  top: 5px;}
.call-for-action-block{position: relative; padding-left: 50px; display: block !important;}
.call-for-action-block h5{max-width: 100%; font-size: 20px !important; line-height: 22px !important; }
.call-for-action-block img{position: absolute; left: 0; top: 0;}
.section-title{margin-bottom: 30px !important; text-align: center;}
.image-gallery img{object-position: center top;}

.call-to-action{padding: 1.5rem 0 !important;}
.call-to-action .form p{margin-bottom: 15px; font-weight: 500; font-size: 18px !important;}
.call-to-action .form .email-address{display: block ; width: 100%; margin-bottom: 20px;}
.call-to-action .form input{ width: 100%; color: #fff !important;}
.call-to-action .form div{width: 100%;}
.call-to-action .form label{color: #fff !important; background: none !important; padding: 0;}
.call-to-action .form fieldset{border-color: #fff !important;}
.call-to-action .scan p, .call-to-action .scan .scanDiv{display: inline-block; vertical-align: middle;}
.call-to-action .scan p{width: 20%; font-weight: 700;}
.call-to-action .scan .scanDiv{width: 75%; text-align: center;}
.call-to-action .scan .scanDiv p{width: auto !important;}
.call-to-action .scan .scanDiv img{max-width: 200px; margin: 15px auto 0 auto; display: block;}
/* .call-top{border-bottom: solid 1px #fff; padding-bottom: 30px; margin-bottom: 30px; } */
/* .face-book-block{ text-align: center;} */
.face-book-block svg{width: 50px; height: 50px; color: #4267B2; margin-right: 10px;}
.face-book-block button, .face-book-block a{font-size: 20px !important; color: #000 !important;}
.event-lists .event-card{position: relative;     min-height: 365px;}
.event-lists .event-card p{margin-top:  5px; font-weight: 500;    color: #000 !important; font-size: 13px;}
.event-lists .event-card .link{position: absolute; right: 10px; bottom: 0; color: var(--clrPrimary); }
.event-lists .event-card .link svg{height: 40px; width: 40px;}
.event-lists .event-card svg{color: #8B1A10; display: inline-block; vertical-align: middle; height: 15px; position: relative; top: -1px;}
.event-lists .event-card h3{border-bottom: solid 1px var(--clrPrimary); padding-bottom: 15px; font-size: 15px !important; line-height: 18px !important;}
.static-event-card{height: 365px; overflow: hidden; position: relative;  max-width: 350px;}
.static-event-card::before{content: ""; position: absolute; left: 0; top: 0; width: 100%; height: 100%; background: rgba(0,0,0,0.5); border-radius:10px; border-top-right-radius: 50px;}
.static-event-card a{position: absolute; left: 28%; top: 48%; background: var(--clrPrimary); color: #fff; font-weight: 700; padding: 7px 15px; border-radius: 8px;}


.slick-prev:before, .slick-next:before{display: none !important;}
.slick-prev{left: 0;  background: url(../images/slick-arrow.svg)   !important; }
.slick-next{right: 10px; background: url(../images/slick-arrow.svg)   !important;   transform: rotate(180deg);}
.slick-prev, .slick-next{width: 50px; height: 50px;  background-size: cover !important; background-position: center !important;  z-index: 99}
.event-calender .event-calender-card{text-align: center; padding: 10px !important; width: 320px;}
.event-calender .event-calender-card h2{

  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.5rem;
  margin-bottom: 0px;
}
.event-calender .event-calender-card .css-5wchs2-MuiDateCalendar-root{    max-height: 315px;
}

a.text-primary:hover{background: var(--clrTertiary) !important; color: #fff !important;}
.inner-banner{position: relative; padding: 0 !important; }
.inner-banner::before{content: ""; position: absolute; left: 0; top: 0; width: 100%; height: 100%; background:#000; opacity: 0.5; z-index: 1;}
.inner-banner .banner-pic{position: absolute; left: 0; top: 0;   width: 100%; height: 100% !important; object-fit: cover; }
.inner-text{position: relative; z-index: 2; padding: 30px 0;}


 .inline-radio{justify-content: center !important; align-items: start !important; display: flex !important; flex-direction: row !important;}

.inline-radio li{max-width: 250px !important; margin: 0 10px !important;  } 
.prizes-radio{border: solid 1px #ccc; padding: 30px; margin-top: 30px !important; justify-content: center !important; }
.prizes-radio .radiogroup{justify-content: center;}
.mb-10{margin-bottom: 10px !important;}
.balvihar_deepam{padding-bottom: 0;}
.balvihar_deepam .card{ position: relative;     height: 100px; overflow: hidden; }
.balvihar_deepam .card .link{position: absolute; right: 5px; bottom: 10px; color: var(--clrTertiary); font-weight: 700;}
.balvihar_deepam .card .link svg{display: inline-block; vertical-align: top; position: relative; top: 3px;}
.balvihar_deepam .card:hover h4, .balvihar_deepam .card:hover  .link{color: #fff !important;}
.upcoming-events{padding: 30px 0;}

.footer-inner{border-radius: 0;}
.custom-table{ border-width: 1px !important; border-color: #ccc !important; border: solid 1px #ccc; width: 100%;}
.custom-table  tr th{background: var(--clrPrimary); color: #fff !important;}
.custom-table  tr th, .custom-table  tr td{border-width: 1px !important; border-color: #ccc !important;  padding: 10px;}
.custom-table  tr td{ color: #000 !important;}
.custom-table  tr:nth-child(odd){background: #fff;}
.custom-table  tr td{border-left: solid 1px #ccc;}
.custom-table  tr td:first-child, .custom-table  tr th:first-child{border-left: none;}
.custom-table  tr th{border-left: solid 1px #ccc;}
.responsive-table{width: 100%; overflow: auto;}
.inner-text .section-title{text-align: left;}
.inner-text .section-title.text-center{text-align: center;}
.circular-progress{text-align: center; padding: 100px 0 0 0;}

.sub-menu a{display: block; position: relative;}
.sub-menu a svg{width: 18px; position: absolute; right: 0; top: 0;}

.team-list h3{border-bottom: solid 1px #ccc; padding-bottom: 15px; font-size: 30px; font-weight: 700; margin-bottom: 30px;}
.team-list li span{display: block; font-size: 14px; }
.team-list ul{margin: 0; padding: 0; list-style: none; display: flex; flex-wrap: wrap;}
.team-list ul li{margin: 0 10px 30px 10px; text-align: center;  font-weight: 500;
border-radius: 4px;
flex: 0 0 22%;
  vertical-align: top;
  font-size: 14px;
  padding: 10px ;
 border: solid 1px #efe6d9; display: block;
  box-shadow: 3px 9px 10px #efe6d9; background: #fffafa;}
  .team-list h3{margin-top: 70px;}
  .team-list h3:first-child{margin-top: 0;}
  .team-list p{color: var(--clrPrimary); font-weight: 500;}

  .staff-top svg{margin-right: 10px; color: #000 !important;}
  .staff-top{position: relative; padding-left: 170px; min-height: 160px; border-bottom: solid 1px #ccc;}
  .staff-pic{width: 140px; height: 140px; overflow: hidden; border-radius: 100%; border: solid 2px var(--clrPrimary); position: absolute; left: 0; top: 0;}
  .staff-pic img{width: 100% !important; height: 100% !important; }
  .staff-top a{margin-top: 10px; color: #000; font-size: 14px;}
  ol {margin: 0 0 0 30px; padding: 0;}
  ol li{padding-left: 15px; margin-bottom: 15px;} 
.responsive-video iframe{max-width: 800px; margin: 0 auto; display: block;}
.inner-text ol li{padding-left: 0;}

.mantra-list{margin: 0; padding: 0; list-style: none;}
.mantra-list li{border-bottom: solid 1px #ccc; padding: 15px; margin-bottom: 15px; background: #fff;}
.mantra-list li h6{margin-bottom: 0;}

.card-list a{font-size: 18px; color: #000; font-weight: 600; display: block; position: relative;}


  .card-list a::after{content: ""; position: absolute; right: 8px; top: 3px;
    width: 0; 
    height: 0; 
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    
    border-left: 15px solid var(--clrPrimary);  }

    .card-list .card:hover{background: var(--clrPrimary) !important;}
    .card-list .card:hover a{color: #fff !important; text-decoration: none !important;}
    .card-list .card:hover a:after{ border-left: 15px solid #fff;}
    
    .staff-content,     .staff-content p {line-height: 32px !important;}

    .related-events{background: #fff; padding: 15px;     box-shadow: 0 1rem 5rem rgba(0, 0, 0, .1) ;}
    .related-events-lists{ margin-bottom: 20px; border-bottom: solid 1px #ccc; padding-bottom: 15px;}
    .related-events-lists:last-child{border: none; padding-bottom: 0; margin-bottom: 0;}
    .related-events-lists .event-card{position: relative; padding-left: 130px;  border-radius: 0 !important; background: none !important; box-shadow: none !important;}
    .related-events-lists .event-card img{width: auto; height: 70px !important; position: absolute; left: 10px; top: 15px; border-radius: 5px !important;}
    .related-events-lists  .event-lists .event-card{min-height: 10px;}

    .event-details-title{border-bottom: solid 1px #ccc; padding-bottom: 15px; margin-bottom: 15px !important;}
   .detail-date{position: relative; margin-bottom: 10px;}
   .detail-date svg{display: inline-block; vertical-align: top; margin-right: 15px; color: var(--clrPrimary);}
   .event-page .event-lists img{height: 300px !important;}
   .event-page .MuiTabs-scroller{background: #fff; text-align: left;}
   .event-page .MuiTabs-scroller button{text-align: left !important; align-items: start; border-bottom: solid 1px #cccccc; font-weight: bold; color: #000 !important;}
   .event-page .MuiTabs-scroller button.Mui-selected{background: var(--clrPrimary); color: #fff !important;}
    

   .custom-card{background: #fff !important; padding: 15px;   box-shadow: 0 1rem 3rem rgba(0, 0, 0, .1) !important ;}
   .project-cart{}
   .project-cart-list{border: solid 1px #fff; }
   .project-cart-list:hover{border: solid 1px var(--clrPrimary);}
   .project-cart-list button{display: block; width: 100%; border-radius: 10px !important; margin-top: 50px; box-shadow: none !important; border: solid 1px #fff;}
   .project-cart-list button svg{display: inline-block; vertical-align: top;}
   .project-cart-list.active{border-color: var(--clrPrimary) !important; background: #fff1f1 !important;}

   .project-cart-list.active h5{}
   .project-cart-list.active button{background: #767676 !important; border: solid 1px #767676;}

   .project-cart-list{position: relative;}
   .project-cart-list .check-mark{position: absolute; right: 5px; top: -3px; z-index: 999; color: green; width: 15px; display: none;}
   .project-cart-list.active  .check-mark{display: block;}

   .success-banner{height: 200px;}
   .success-card{padding: 70px 30px;}
   .btn-inline{display: inline-block !important; width: auto !important;}

 .specify-page{text-align: center;}
 .specify-page-buttons{justify-content: center !important;}
 .border-title{border-bottom: solid 1px #ccc; padding-bottom: 15px; margin-bottom: 15px !important;}

 .no-shadow{box-shadow: none !important;}
 .bg-grey{background: #eee;}
 .checkout-footer{padding: 0px 0 20px 0; margin-top: 30px;}

 .grand-total{border-top: solid 1px #ccc; padding-top: 10px; margin-top: 20px !important;}
 .items-list svg{display: inline-block; vertical-align: top; margin-right: 5px; width: 18px; color: green;}

 .project-cart h5{font-size: 18px !important;}

 .hall-list-item{border-bottom: solid 1px #e1e1e1; padding-bottom: 15px; margin-bottom: 15px;}
 .addon-list{background: #fcf8f4; padding: 15px;}
 .addon-list h6{margin-bottom: 10px;}
 .addon-list span{font-size: 13px;}
 .addon-list .Mui-disabled span.MuiTypography-root{color: #000 !important;}
 .total-cost-block h5{display: inline-block; border: dotted 2px var(--clrTertiary); padding: 15px;}
 .selected-hall-item{border-bottom: solid 1px #ccc; padding-bottom: 10px;}
 .selected-hall-item:last-child{border: none; padding-bottom: 0;}
 .selected-hall-item .items-list .addon-name{font-size: 14px; max-width: 80%;}
 .selected-hall-item .items-list .addon-name svg{width: 15px; position: relative; top: -3px; color: #000;}
 .css-1mior1k-MuiFormLabel-root-MuiInputLabel-root{background: #fff;}
 .bg-light{background: #fcf8f4 !important;} 
 .p-30{padding: 30px;}
 .address-section{margin-bottom: 30px;}
 .address-section p{position: relative; font-size: 13px; color: #000;padding-left: 150px; margin-bottom: 15px;}
 .address-section span{position: absolute; left: 0; top: 0; font-weight: bold; font-size: 13px;}

 .check-hall-availability svg{ display: inline-block; vertical-align: top;}
 .social-hall-booking .check-hall-availability{padding: 10px 0 !important;}

 .annual-sponsorship-lists .MuiGrid2-grid-md-4{background: #fff;  height: 530px; border: solid 1px #ccc; }
 .annual-sponsorship-lists .MuiGrid2-grid-md-4 img{width: 100%; height: 100%; object-fit: contain;}

 .terms-conditions ul{margin: 0 0 0 20px; padding: 0; list-style: none;}
 .terms-conditions ul li{position: relative; font-size: 13px; margin-bottom: 15px; padding-left: 30px;}
 .terms-conditions ul li::before{content: ""; position: absolute; left: 0; top: 5px; width: 10px; height: 10px; background: var(--clrPrimary); border-radius: 100%;}
 .related-events-lists .event-lists .event-card p{font-size: 10px;}

 .MuiFormControl-root label{background: #fff; padding: 0 5px;}

 .selected-hall-item:last-child{border-bottom: none !important;}

.community-link-address{margin: 15px 0;}
.community-link-address label{background: #fcf8f3; display: block;  font-weight: bold;  padding: 5px 30px 5px 10px; border-radius: 12px; position: relative; cursor: pointer; border: solid 1px #eee; display:  block !important;  margin-right: 40px !important;}
.community-link-address .MuiRadio-colorPrimary{position: relative; top: -2px;}

 .custom-radio-label{margin-right: 30px;}

 .custom-radio-label label::before{content: ""; position: absolute; left: 12px; top: 12px; width: 15px; height: 15px; border-radius: 100%; border: solid 1px #777; }
 .custom-radio-label label::after{content: ""; position: absolute; left: 16px; top: 16px; width: 7px; height: 7px; border-radius: 100%; background: var(--clrTertiary); display: none; }
 .custom-radio-label:hover label{border-color: var(--clrPrimary);}
 .custom-radio-label input:checked ~ label::after{display: block;}
 .custom-radio-label input:checked ~ label{background: var(--clrTertiary); color: #fff;}
 .custom-radio-label input:checked ~ label::before{background: #fff; border-color: #fff;}

 .selected-hall-item .items-list span:first-child, .custom-card .items-list span:first-child {max-width: 80%; }