.logo{ border-right: solid 10px #fff; border-bottom: solid 10px #fff; position: absolute; left: 0; top: 0; max-width: 230px; z-index: 2;}
.logo img{width: 100%; height: 100%; display: block;}
header{padding:  0;}
.header-inner{padding: 0 !important; justify-content: flex-end; }
.nav{margin-left: 50px !important;}
.nav li{display: inline-block; width: auto; margin: 0 15px; position: relative; padding: 0;}
.nav li a, .nav li a span{ font-family: "Roboto Condensed", serif; color: #000; font-size: 22px; line-height: 26px; padding: 8px 15px; font-weight: 600;}
.nav li a svg{display: inline-block; vertical-align: top;}

.nav li:hover .sub-menu{display: block;}
.sub-menu{background: #fff; text-align: left !important;  padding: 15px !important;  position: absolute !important; left: 0; top: 100%; width: 350px !important; z-index: 999 !important; display: none;}
.sub-menu::before{content: "";
position: absolute;
left: 30%;
top: -5px;
width: 0; 
  height: 0; 
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  
  border-bottom: 5px solid black;}
.sub-menu li{border-bottom: solid 1px #ccc;  text-align: left !important; margin: 0 !important; padding: 0 !important; display:  block; width: 100% !important; position: relative;}
.sub-menu li:last-child{border-bottom: none !important;}
.sub-menu li a, .sub-menu li a span{text-align: left !important; padding: 3px 0 !important; display:  block; width: 100% !important; font-size: 16px !important; line-height: 20px;}
.sub-menu-text{display: inline-block;}
.sub-menu li .sub-sub-menu{position: absolute; left: 100%; top: -30px; width: 250px; background: #ecebeb; padding: 15px; display: none;}
.sub-menu li:hover .sub-sub-menu{display: block;}

.custom-table tr th, .custom-table tr td{text-align: left;}

